<template>
  <q-dialog v-model="dialogUser" @before-show="prepareDialog" persistent>
    <q-card style="min-width: 600px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> {{ userSelect ? $t('edit') : 'Cadastro de' }} {{ $t('user') }} </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>
      <q-form @submit="onSave">
        <q-card-section class="row scroll q-col-gutter-md" style="max-height: 80vh">
          <div class="col-12">
            <q-input outlined v-model="form.email" type="email" label="Email" lazy-rules :rules="[isRequired, isEmail]">
            </q-input>
          </div>
          <div class="col-12">
            <q-input outlined type="text" :label="$t('name')" v-model="form.name" lazy-rules :rules="[isRequired]" />
          </div>
          <div class="col-12">
            <!-- <select-request
            :v-model="form.profile"
            outlined
            label="Perfil"
            endpoint="get/profile"
            option-label="title"
            option-value="id"
            :rules="[(val) => !!val || 'Field is required']"
            @update="(value) => (form.profile = value)"
          /> -->
            <q-select
              v-model="form.profile_id"
              :options="profiles"
              :label="$t('profile')"
              option-label="title"
              option-value="id"
              outlined
              :loading="loading_profile"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-12">
            <q-select
              v-model="form.role_id"
              :options="roles"
              :label="$t('occupation')"
              option-label="role"
              option-value="id"
              outlined
              :loading="loading_role"
              lazy-rules
              :rules="[isRequired]"
            />
            <!-- <select-request
            :v-model="form.role"
            outlined
            label="Função"
            endpoint="get/role"
            option-label="role"
            option-value="id"
            @update="(value) => (form.role = value)"
          /> -->
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
          <q-btn unelevated :label="$t('save')" no-caps padding="sm lg" color="primary" type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import UserMixin from '../../mixins/UserMixin';
// import SelectRequest from "@/widgets/inputsGlobal/SelectRequest.vue";
export default {
  components: {
    //   SelectRequest
  },
  name: 'FormUser',
  mixins: [UserMixin],
  data() {
    return {
      form: {
        email: null,
        name: null,
        profile_id: null,
        role_id: null,
        multi_factor_auth: 0
      },
      roles: [],
      loading_role: false,
      loading_profile: false,
      profiles: []
    };
  },
  methods: {
    async prepareDialog() {
      await this.getRoles();
      await this.getProfiles();
      if (this.userSelect && this.dialogUser) this.setValuesUser();
    },
    setValuesUser() {
      this.form.name = this.userSelect.name;
      this.form.email = this.userSelect.email;
      this.form.profile_id = this.profiles.find((item) => item.id == this.userSelect.profile_id);
      this.form.role_id = this.roles.find((item) => item.id == this.userSelect.role_id);
      console.log(this.form, this.userSelect, this.profiles);
    },
    onClose() {
      this.ActionSetDialogUser(false);
    },
    onSave() {
      this.form.profile_id = this.form.profile_id.id;
      this.form.role_id = this.form.role_id.id;
      if (this.userSelect) this.updateUser(this.userSelect.id);
      else this.createUser();
    },
    async getRoles() {
      this.loading_role = true;
      try {
        const { data, status } = await this.$http.get('get/role');
        if (status === 200) this.roles = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading_role = false;
      }
    },
    async getProfiles() {
      this.loading_profile = true;
      try {
        const { data, status } = await this.$http.get('get/profile');
        if (status === 200) this.profiles = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading_profile = false;
      }
    },
    async createUser() {
      console.log(this.form);
      this.onLoading(true);
      try {
        const { status } = await this.$http.post('create/manager', this.parseQs(this.form));
        if (status === 200 || status === 201) {
          this.getUsers();
          this.ActionSetDialogUser(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async updateUser(id) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.put('users/edit/' + id, this.parseQs(this.form));
        if (status === 200 || status === 201) {
          this.getUsers();
          this.ActionSetDialogUser(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
