<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md">
      <q-toolbar-title class="text-5">{{ $t('users') }}</q-toolbar-title>
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('filter_users') }}
        </q-tooltip></q-btn
      >
      <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="exportTableCsv(prepareItemCSV(users), prepareColumnCSV(columns), 'users')"
        unelevated
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('export_csv') }}
        </q-tooltip>
      </q-btn>
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        :label="$t('add')"
        no-caps
        @click="ActionSetDialogUser(true)"
      />
    </q-toolbar>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter_by") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <span class="text-weight-medium">Email</span>
          <q-input outlined dense v-model="params.email" type="email" />
        </div>
        <div class="col-4">
          <span class="text-weight-medium">{{ $t('name') }}</span>
          <q-input outlined dense v-model="params.name" type="text" />
        </div>
        <div class="col-4">
          <span class="text-weight-medium">{{ $t('profile') }}</span>
          <select-request
            :v-model="params.profile"
            outlined
            dense
            endpoint="get/profile"
            option-label="title"
            option-value="id"
            @update="(value) => (params.profile = value)"
          />
        </div>

        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterUsers" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import UserMixin from '../../mixins/UserMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
export default {
  name: 'HeaderUser',
  mixins: [UserMixin],
  components: { SelectRequest },
  data() {
    return {
      filter: true,
      params: {
        email: null,
        name: null,
        profile: null
      },
      profiles: []
    };
  },
  methods: {
    clearFilter() {
      this.params = {
        email: null,
        name: null,
        profile: null
      };
      this.ActionSetFilterUser();
      this.getUsers();
    },
    onFilterUsers() {
      let filterSelect = [];
      if (this.params.email) filterSelect.push(`email=${this.params.email}`);
      if (this.params.name) filterSelect.push(`name=${this.params.name}`);
      if (this.params.profile) {
        if (Array.isArray(this.params.profile))
          filterSelect.push(`profile=${this.params.profile.map((item) => item.id)}`);
        else filterSelect.push(`profile=${this.params.profile.id}`);
      }

      this.ActionSetFilterUser(filterSelect.join('&'));
      this.getUsers();
    }
  }
};
</script>

<style></style>
