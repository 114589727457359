<template>
  <q-table
    flat
    bordered
    :data="usersAll"
    row-key="id"
    :columns="columns"
    :pagination="pagination"
    class="sticky-column-table"
  >
    <!-- :filter="filterUser" -->
    <template v-slot:body-cell-name="props">
      <q-td :props="props" style="max-width: 210px" class="ellipsis">
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ props.row.name }}
        </q-tooltip>
        {{ props.row.name }}
      </q-td>
    </template>
    <template v-slot:body-cell-blocked="props">
      <q-td :props="props">
        <q-toggle
          v-model="props.row.blocked"
          :color="props.value ? 'negative' : 'primary'"
          keep-color
          :false-value="1"
          :true-value="0"
          :ref="`blocked-${props.row.id}`"
          @input="updateuserBlocked(props.row)"
        >
          <div
            v-html="props.value ? $t('blocked') : $t('active')"
            :class="props.value ? 'text-negative' : 'text-primary'"
          ></div>
        </q-toggle>
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          @click="ActionSetDialogUser({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('edit') }}
          </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:bottom>
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>
<script>
import UserMixin from '../../mixins/UserMixin';
export default {
  name: 'ListUser',
  mixins: [UserMixin],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getUsers(`page=${this.currentPage}`, `&perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    updateuserBlocked(value) {
      let user = value;
      this.$q
        .dialog({
          title: this.$t('attention'),
          message: value.blocked == 0 ? this.$t('unlock_user') : this.$t('block_user'),
          html: true,
          persistent: true,
          class: 'q-pa-md',
          ok: {
            value: true,
            padding: 'sm lg',
            label: this.$t('yes'),
            unelevated: true,
            color: 'primary',
            ['no-caps']: true
          },
          cancel: {
            value: true,
            padding: 'sm lg',
            label: this.$t('cancel'),
            unelevated: true,
            color: 'negative',
            ['no-caps']: true
          }
        })
        .onOk(async () => {
          this.onLoading(true);
          try {
            const { data, status } = await this.$http.post(`block-user/${value.id}`);
            if (status === 200) {
              this.successNotify(data);
              this.ActionSetDialogUser(false);
            }
          } catch (error) {
            if (error.response && error.response.status === 500)
              this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
            else this.errorNotify(error);
          } finally {
            this.onLoading(false);
          }
        })
        .onCancel(() => {
          user.blocked == 1 ? (user.blocked = 0) : (user.blocked = 1);
          this.ActionUpdateUser(user);
          // console.log(this.$refs["blocked-" + id]);
        })
        .onDismiss(() => {});
    }
  },
  created() {
    this.ActionSetFilterUser(null);
    this.getUsers(null, '&perPage=' + this.pagination.rowsPerPage);
  }
};
</script>

<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
