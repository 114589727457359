<template>
  <q-page padding>
    <header-user />
    <list-user />
    <form-user v-if="dialogUser" />
  </q-page>
</template>

<script>
import HeaderUser from '../components/user/HeaderUser.vue';
import FormUser from '../components/user/FormUser.vue';
import ListUser from '../components/user/ListUser.vue';
import UserMixin from '../mixins/UserMixin';

export default {
  components: { ListUser, FormUser, HeaderUser },
  name: 'PageUser',
  mixins: [UserMixin]
};
</script>
